import type { ComponentProps } from "react";
import { useFormContext } from "react-hook-form";

import { FormFieldIdentifier } from "../core/FormFieldIdentitifer.js";

interface Props extends ComponentProps<typeof FormFieldIdentifier> {
  value: any;
}

const HiddenFormInput = ({ name, value, ...props }: Props) => {
  const { register } = useFormContext();
  return (
    <>
      <FormFieldIdentifier name={name} {...props} />
      <input hidden value={value} readOnly {...register(name)} />
    </>
  );
};

export default HiddenFormInput;
